import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import ActionTypes from "./private_data_action_types";
import {fetchNamesAPI} from "./private_data_api";

const loadNamesRequestAction = () => ({
    type: ActionTypes.PRIVATE_DATA_REQUEST
});

const loadNamesSuccessAction = (payload, nameType, replace, isAll) => ({
    type: ActionTypes.PRIVATE_DATA_SUCCESS,
    payload,
    nameType,
    replace,
    isAll
});

const loadNamesFailureAction = (error) => ({
    type: ActionTypes.PRIVATE_DATA_FAILURE,
    error
});

/**
 * load names
 * @param {String} type
 * @param {Array} ids
 * @param {Boolean} replace
 * @param {Boolean} [isAll = false] this flag is used to store all the names of patients and used to filter the patient by name in the SearchFilter.
 * @return {AnyAction}
 */
function loadNamesAction(type, ids, replace, isAll = false) {
    return function (dispatch, getState) {
        const organizationId = selectCurrentOrganizationId(getState());
        dispatch(loadNamesRequestAction());

        fetchNamesAPI(organizationId, type, ids)
            .then(({data}) => {
                dispatch(loadNamesSuccessAction(data.data, type, replace, isAll));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch employees error"}));
                } else {
                    dispatch(loadNamesFailureAction(error));
                }
            });
    };
}

const clearNamesAction = () => ({
    type: ActionTypes.PRIVATE_DATA_CLEAR
});

export {loadNamesAction, clearNamesAction};
